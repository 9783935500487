import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class WhyChoose extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="bluecolor">Why Choose Coinsclone</span> as a DeFi DEX Aggregator Development Company?</h2>
              
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/defidex/why-choose-defi.webp"
                alt="DeFi DEX Aggregator"
                placeholder='none'
                className='mt-3 mb-3'
                width={485}
              />
            </div>
            <div className="col-lg-6 col-md-12">
            <p className="pharagraph">Coinsclone is a reputed DeFi DEX Aggregator development company holding 7+ years of experience in the crypto field. We specialize in decentralized finance development, focusing on creating solutions that integrate with multiple decentralized exchanges to aggregate liquidity and optimize trading processes.</p>
            <p className="pharagraph">Coinsclone has a proven record for building secure and efficient DeFi applications, including DEX aggregators. We provide you with a tailored DEX aggregator development service that can be customized based on specific business requirements. Our team of experts helps you to build a robust and competitive DEX aggregator that meets market demands and positions your platform for success within the evolving DeFi ecosystem.</p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose
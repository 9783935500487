import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class BannerSection extends React.Component {


  render() {

    return (
      <section className="banner alngpad wallet-banner">  
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side mb-3 mb-lg-0">
                <h1 className="main-heading"><span className='bluecolor'>DeFi DEX </span>Aggregator Development Company</h1>
                <p className='pharagraph'>Coinsclone, a leading DeFi DEX aggregator development services offers a combination of technical expertise, innovative solutions, commitment to security and compliance, and comprehensive support services. Our DeFi DEX aggregator software is crafted with innovation and user experience in mind, offering robust features. </p>
                <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Talk to our Experts</a>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/defidex/defi-dex-aggregator.webp"
                alt="DeFi DEX Aggregator Development"
                className='d-none d-md-block float-end'
                width={616}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection
import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100 mb-5">
      <Container>
        <h2 className="main-heading text-center">Frequently Asked Questions</h2>
        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" eventKey="0">
                What is DeFi DEX Aggregator Development?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="0"> 
                <div className="panel-body card-body">DeFi DEX Aggregator Development involves creating platforms that aggregate liquidity from multiple decentralized exchanges. This helps users find the best prices and execute trades efficiently across various DeFi protocols. If you want to create your own DeFi Aggregator platform, you can hire a DeFi DEX Aggregator development company like Coinsclone.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle"  eventKey="1">
                What are the best DeFi aggregators in the marketplace?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">Some popular DeFi aggregators include 1inch, Matcha, Paraswap, and DEX.AG. Each offers unique features like price optimization, gas fee savings, and access to a wide range of liquidity pools. Like the above-mentioned aggregators, you can also develop a DeFi DEX Aggregator with similar features they have.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle"  eventKey="2">
                How does the DeFi aggregator make money?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">DeFi aggregators typically generate revenue through various mechanisms such as trading fees, liquidity provider fees, and premium services. By creating one you can also create your own DeFi DEX Aggregator software and make a profit through it.
                </div>
              </Accordion.Collapse>
            </div> 
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle"  eventKey="3">
                How much does it cost to develop a DEX Aggregator?
                </Accordion.Toggle>
              </div>  

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">The cost can vary significantly based on complexity, features, development team rates, and technology stack. A basic DEX aggregator might start around $40,000 to $100,000, while more advanced platforms with custom features could cost upwards or more. To find out more information about the cost of developing a DeFi aggregator software, schedule a call with us.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle"  eventKey="4">
                How long does it take to create a DeFi DEX Aggregator?
                </Accordion.Toggle>
              </div>  

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">The development time depends on the scope and complexity of the aggregator. Building a basic DEX aggregator might take 2-4 months, while a more feature-rich platform could take 6 months or longer. Our rapid development frameworks and existing libraries can sometimes shorten this timeline.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion> 
      </Container>
    </section>
  )
}

export default FaqSection